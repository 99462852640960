<template>
  <div>
    <vue-apex-charts
      type="pie"
      :height="height"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'PieChart',
  components: {
    VueApexCharts,
  },
  props: [
    'dataLabels',
    'dataSeries',
    'height',
  ],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        labels: null,
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [$themeColors.light],
          colors: [$themeColors.warning],
        },
        stroke: {
          curve: 'straight',
        },
        tooltip: {
          tooltip: {
            custom(data) {
              return `<div class="px-1 py-50"><span>${this.dataLabels[data.dataPointIndex]}</span>: ${data.series[data.seriesIndex][data.dataPointIndex]}</div>`
            },
          },
        },
      },
    }
  },
  watch: {
    dataSeries(val) {
      if (val) {
        this.setChartData()
      }
    },
  },
  created() {
    this.setChartData()
  },
  methods: {
    setChartData() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: this.dataLabels,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                heigth: this.height,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
        },

      }
      this.series = this.dataSeries
    },
  },
}
</script>

<style scoped>

</style>
