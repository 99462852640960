<template>
  <div>
    <b-card title="Filtrele">
      <b-row>
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="reportQuery.sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="reportQuery.edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button
            :disabled="loadingReport"
            variant="primary"
            @click="setReport"
          >
            <template v-if="loadingReport">
              <b-spinner
                class="mr-1"
                variant="light"
                small
              />
              Yükleniyor..
            </template>
            <template v-else>
              <feather-icon icon="PieChartIcon" />
              Raporu Getir
            </template>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <template v-if="report">
      <b-card
        v-if="report"
        title="Genel Durum"
      >
        <b-row>
          <b-col>
            <b-list-group class="text-center">
              <list-item
                label="Toplam Servis Sayısı"
                :value="String(report.totalServiceCount)"
              />
            </b-list-group>
          </b-col>
          <b-col>
            <b-list-group class="text-center">
              <list-item
                label="Toplam Çalışma Saati"
                :value="String(report.totalServiceWorkTime)"
              />
            </b-list-group>
          </b-col>
          <b-col>
            <b-list-group class="text-center">
              <list-item
                label="Toplam Müşteri Sayısı"
                :value="String(report.totalCustomers)"
              />
            </b-list-group>
          </b-col>
          <b-col>
            <b-list-group class="text-center">
              <list-item
                label="Toplam Makine Sayısı"
                :value="String(report.totalMachines)"
              />
            </b-list-group>
          </b-col>
        </b-row>
      </b-card>
      <b-row class="match-height">
        <b-col
          v-if="report"
          cols="12"
          md="12"
        >
          <b-card title="En Çok Servise Gidilen 10 Firma">

            <pie-chart
              :data-labels="report.best10ServiceCustomers.categories"
              :data-series="report.best10ServiceCustomers.series"
              height="300"
            />
          </b-card>
        </b-col>
        <b-col>
          <b-card
            title="En Çok Ziyaret Edilen 10 Şehir"
          >
            <pie-chart
              :data-labels="report.mostVisitedCities.categories"
              :data-series="report.mostVisitedCities.series"
              height="300"
            />
          </b-card>
        </b-col>
        <b-col>
          <b-card title="En Çok Gerçekleştirilen 10 İşlem Oranı">
            <pie-chart
              :data-labels="report.chartJobData.categories"
              :data-series="report.chartJobData.series"
              height="300"
            />
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormDatepicker, BButton, BSpinner, BListGroup,
} from 'bootstrap-vue'
import ListItem from '@/views/Machines/View/ListItem.vue'
import PieChart from '@/views/Reports/Company_reports/Components/PieChart.vue'

export default {
  name: 'CustomerServiceReport',
  components: {
    PieChart,
    BListGroup,
    ListItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BSpinner,
  },
  data() {
    return {
      reportQuery: {
        sdate: null,
        edate: null,
      },
      loadingReport: false,
    }
  },
  computed: {
    report() {
      return this.$store.getters['companyReport/getReport']
    },

  },
  created() {
    this.setReport()
  },
  methods: {
    setReport() {
      this.loadingReport = true
      this.$store.dispatch('companyReport/setReport', {
        sdate: this.reportQuery.sdate,
        edate: this.reportQuery.edate,
      })
        .then(response => {
          this.loadingReport = false
        })
    },
  },
}
</script>
